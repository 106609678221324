import React from 'react';
import '../../App.css';
import { makeStyles } from '@material-ui/core/styles';
import theme from '../../styles/theme.js';
import { Typography, TextField } from '@material-ui/core';
import neritoIcon from '../../assets/images/logos/neritoIcon.png';
import PrimaryButton from '../../components/common/primaryButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, } from '@fortawesome/free-solid-svg-icons';

const Contact = (props) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.rowContainer}>
        <div className={classes.leftContainer}>
          <Typography className={classes.blackTitle}>
            Contacto
          </Typography>
          <div className={classes.infoContainer}>
            <div className={classes.innerleftContainer}>
              <div className={classes.inputContainer}>
                <TextField
                  id="name"
                  label="Nombre"
                  variant="outlined"
                  fullWidth
                />
              </div>
              <div className={classes.inputContainer}>
                <TextField
                  id="email"
                  label="Correo electrónico"
                  variant="outlined"
                  fullWidth
                  margin="30px"
                />
              </div>
              <div className={classes.inputContainer}>
                <TextField
                  id="message"
                  label="Tu mensaje"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={4}
                />
              </div>
              <PrimaryButton marginVertical={'20px'} buttonText={classes.buttonText} minWidth={'300px'}>Enviar</PrimaryButton>
            </div>
            <div className={classes.innerRightContainer}>
              <img src={neritoIcon} alt='' className={classes.neritoBussines} />
              <div className={classes.contactInfo}>
                <div className={classes.contactInfoRowContainer}>
                  <div className={classes.contactInfoIconContainer}>
                    <FontAwesomeIcon icon={faPhone} size='1x' color={theme.palette.black.main} />
                  </div>
                  <Typography>6622123456</Typography>
                </div>
                <div className={classes.contactInfoRowContainer}>
                  <div className={classes.contactInfoIconContainer}>
                    <FontAwesomeIcon icon={faEnvelope} size='1x' color={theme.palette.black.main} />
                  </div>
                  <Typography>correo@nerito.com</Typography>
                </div>
                {/* <div className={classes.contactIcons}>
                  <FontAwesomeIcon icon={faFacebookF} size='2x' color={theme.palette.black.main} />
                  <FontAwesomeIcon icon={faInstagram} size='2x' color={theme.palette.black.main} />
                  <FontAwesomeIcon icon={faLinkedinIn} size='2x' color={theme.palette.black.main} />
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className={classes.rightContainer}>
          <div className={classes.textContainer}>
            <Typography className={classes.blackSubtitle}>
              ¿Tienes más preguntas?
            </Typography>
            <Typography className={classes.thinTitle}>
              Contáctanos y resolveremos todas tus dudas
            </Typography>
          </div>
        </div>
      </div>
    </>
  )
}

export default Contact;

const useStyles = makeStyles({
  greenTitle: {
    fontFamily: 'Montserrat',
    fontWeight: '700',
    fontSize: 60,
    textAlign: 'center',
    color: theme.palette.primary.main,
    padding: 0,
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      fontSize: 40
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 35,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
    },
  },
  blackTitle: {
    fontFamily: 'Montserrat',
    fontWeight: '700',
    fontSize: 60,
    textAlign: 'left',
    color: theme.palette.black.main,
    padding: 0,
    width: '65%',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      fontSize: 40
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 35,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
    },
  },
  thinTitle: {
    fontFamily: 'Lato',
    fontWeight: '300',
    fontSize: 24,
    [theme.breakpoints.down('md')]: {
      fontSize: 20
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
    },
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '60%',
    [theme.breakpoints.down('md')]: {
      alignItems: 'center',
      padding: 30,
    },
    [theme.breakpoints.down('xs')]: {
      padding: 10,
    },
  },
  leftContainer: {
    width: '70%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  rightContainer: {
    width: '30%',
    display: 'flex',
    flexDirection: 'column',
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingHorizontal: '30px',
    marginTop: '100px',
    alignItems: 'center',
  },
  neritoBussines: {
    width: '100px',
    height: 'auto'
  },
  centerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '50px',
  },
  infoContainer: {
    boxShadow: theme.shadows[5],
    minWidth: '700px',
    minHeight: '500px',
    marginBottom: '100px',
    padding: '50px',
    borderRadius: '20px',
    display: 'flex',
    flexDirection: 'row',
  },
  inputContainer: {
    marginBottom: '30px'
  },
  innerleftContainer: {
    width: '60%',
    display: 'flex',
    flexDirection: 'column',
  },
  innerRightContainer: {
    width: '40%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '40px',
    marginLeft: '10px'
  },
  buttonText: {
    fontFamily: 'Montserrat',
    fontWeight: '700',
    fontSize: '18px',
  },
  contactInfoRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '200px',
    alignItems: 'center',
    height: '30px'
  },
  contactInfoIconContainer: {
    marginRight: '40px',
    marginLeft: '10px'
  },
  contactInfo: {
    marginTop: '50px'
  },
  contactIcons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '200px',
    marginTop: '20px'
  },
  blackSubtitle: {
    fontFamily: 'Montserrat',
    fontWeight: '700',
    fontSize: 34,
    color: theme.palette.black.main,
    padding: 0,
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      fontSize: 40
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 35,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
    },
  },
  thinTitle: {
    fontFamily: 'Lato',
    fontWeight: '300',
    fontSize: 24,
    [theme.breakpoints.down('md')]: {
      fontSize: 20
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
    },
  },
});