import React from 'react';
import '../../App.css';
import { makeStyles } from '@material-ui/core/styles';
import theme from '../../styles/theme.js';
import { Typography } from '@material-ui/core';
import flexedigital from '../../assets/images/flexedigital.png';

const OurGroup = (props) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.rowContainer}>
        <div className={classes.halfContainer}>
          <div className={classes.textContainer}>
            <Typography className={classes.blackTitle}>
              Quienes <br />
            </Typography>
            <Typography className={classes.greenTitle}>somos</Typography>
            <Typography className={classes.thinTitle}>NUESTRO GRUPO</Typography>
          </div>
          <Typography className={classes.ourGroupText}>
            Nos dedicamos a desarrollar servicios y soluciones especializadas 
            con las herramientas tecnológicas adecuadas para potenciar la innovación 
            y el mercado financiero global a través de plataformas de pago, 
            comercio electrónico e inteligencia analítica para negocios y empresas. 
          </Typography>
        </div>
        <div className={classes.halfContainer}>
          <img src={flexedigital} alt='' className={classes.neritoBussines} />
        </div>
      </div>
      <div className={classes.centerContainer}>
        <div className={classes.yellowContainer}>
          <Typography className={classes.text}>Conoce más en <a href="https://flexedigital.com/" className={classes.link}>flexedigital.com</a></Typography>
        </div>
      </div>
    </>
  )
}

export default OurGroup;

const useStyles = makeStyles({
  greenTitle: {
    fontFamily: 'Montserrat',
    fontWeight: '700',
    fontSize: 60,
    textAlign: 'center',
    color: theme.palette.primary.main,
    padding: 0,
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      fontSize: 40
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 35,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
    },
  },
  blackTitle: {
    fontFamily: 'Montserrat',
    fontWeight: '700',
    fontSize: 60,
    textAlign: 'center',
    color: theme.palette.black.main,
    padding: 0,
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      fontSize: 40
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 35,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
    },
  },
  thinTitle: {
    fontFamily: 'Lato',
    fontWeight: '300',
    fontSize: 24,
    [theme.breakpoints.down('md')]: {
      fontSize: 20
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
    },
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    padding: 50,
    [theme.breakpoints.down('md')]: {
      alignItems: 'center',
      padding: 30,
    },
    [theme.breakpoints.down('xs')]: {
      padding: 10,
    },
  },
  halfContainer: {
    width: '50%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    // paddingHorizontal: '30px',
    marginTop: '50px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  neritoBussines: {
    width: '500px',
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '80%',
    },
  },
  ourGroupText: {
    fontFamily: 'Montserrat',
    fontWeight: '500',
    fontSize: 24,
    width: '500px',
    textAlign: 'justify',
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
      width: 'auto',

    },
  },
  centerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '50px',
  },
  yellowContainer: {
    backgroundColor: theme.palette.secondary.main,
    width: '50%',
    padding: '20px',
    borderTopLeftRadius: '30px',
    borderBottomRightRadius: '30px',
  },
  text: {
    fontSize: '20px',
    fontWeight: '500',
    fontFamily: 'Montserrat',
    textAlign: 'center'
  },
  link: {
    fontSize: '20px',
    fontWeight: '700',
    fontFamily: 'Montserrat',
    color: theme.palette.black.main
  }
});