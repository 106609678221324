import React, { useState } from 'react';
// import YouTube from 'react-youtube';
import { Auth } from 'aws-amplify';
import { withSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  InputAdornment,
  IconButton,
  FormControlLabel,
  Checkbox,
  Typography,
  ListItem,
  CircularProgress,
} from '@material-ui/core/';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Circle from '@material-ui/icons/FiberManualRecord';
import theme from '../../styles/theme.js';
import PrimaryInput from '../../components/common/primaryInput';
import PrimaryButton from '../../components/common/primaryButton';
import PrimarySelect from '../../components/common/primarySelect';
import SecondaryButton from '../../components/common/secondaryButton';
import image from '../../assets/images/nerito-marketing-background.png';
import wave from '../../assets/images/nerito-marketing-wave.svg';
import sideSVG from '../../assets/images/background-svg-1.svg';
import countryCodes from '../../lib/countryCodes';
import NeritoHeader from '../../components/headers/thirdHeader';
import CheckValue from '../../lib/formatValidations';
import CognitoErrors from '../../lib/cognitoErrors';
import DefaultModal from '../../components/modal/index.js';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
  registerContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.white.main,
    paddingLeft: '10%',
    paddingRight: '10%',
    paddingTop: '20%',
    paddingBottom: '20%',
    minHeight: 600,
    [theme.breakpoints.down('sm')]: {
      paddingTop: '10%',
      paddingBottom: '10%',
      minHeight: 0,
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '5%',
      paddingRight: '5%',
    },
  },
  centerVertical: {
    ...theme.globals.centerVertical,
    width: '100%',
  },
  phoneContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: '100%',
  },
  checkboxContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    paddingLeft: 5,
  },
  title: {
    ...theme.typography.title,
    color: theme.palette.gray.main,
    paddingTop: 20,
    paddingBottom: 20,
  },
  checkboxText: {
    ...theme.typography.link,
    color: theme.palette.gray.main,
  },
  link: {
    ...theme.typography.link,
    color: theme.palette.primary.main,
    textDecoration: 'none',
    cursor: 'pointer',
  },
  outsideLinkText: {
    ...theme.typography.link,
    color: theme.palette.gray.main,
  },
  outsideLink: {
    ...theme.typography.link,
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
  loginLink: {
    ...theme.globals.center,
    width: '100%',
  },
  imageContainer: {
    backgroundColor: 'white',
    width: '100%',
    height: '100%',
  },
  sideSVG: {
    width: '100%',
    height: '100%',
    margin: 0,
    padding: 0,
    backgroundImage: `url(${sideSVG})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPositionX: 'right',
    backgroundPositionY: 'bottom',
  },
  background: {
    width: '100%',
    height: '100%',
    backgroundImage: `url(${image})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPositionX: 'left',
  },
  wave: {
    backgroundImage: `url(${wave})`,
    width: '100%',
    height: '100%',
    backgroundPositionY: 'bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  promotionalContainer: {
    ...theme.globals.centerVertical,
    backgroundColor: theme.palette.white.main,
  },
  promotionalHeader: {
    ...theme.globals.centerVertical,
    textAlign: 'center',
    paddingLeft: '35%',
    paddingRight: '35%',
    [theme.breakpoints.down('lg')]: {
      paddingLeft: '25%',
      paddingRight: '25%',
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: '20%',
      paddingRight: '20%',
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '10%',
      paddingRight: '10%',
    },
  },
  paragraph: {
    ...theme.typography.bigParagraph,
    color: theme.palette.gray.main,
    paddingTop: 40,
    paddingBottom: 10,
  },
  slogan: {
    ...theme.typography.bigSlogan,
    color: theme.palette.gray.main,
    paddingTop: 10,
    paddingBottom: 10,
  },
  videoContainer: {
    ...theme.globals.centerVertical,
    textAlign: 'center',
    paddingLeft: '15%',
    paddingRight: '15%',
  },
  videoSubtitle: {
    ...theme.typography.subtitle,
    color: theme.palette.primary.main,
    paddingTop: 20,
    paddingBottom: 5,
  },
  videoTitle: {
    ...theme.typography.title,
    color: theme.palette.primary.main,
    paddingTop: 5,
    paddingBottom: 20,
  },
  documentationContainer: {
    ...theme.globals.centerVertical,
    textAlign: 'center',
    paddingLeft: '35%',
    paddingRight: '35%',
    [theme.breakpoints.down('lg')]: {
      paddingLeft: '25%',
      paddingRight: '25%',
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: '20%',
      paddingRight: '20%',
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '10%',
      paddingRight: '10%',
    },
  },
  documentationTitle: {
    ...theme.typography.title,
    color: theme.palette.primary.main,
    paddingTop: 20,
    paddingBottom: 10,
  },
  listContainer: {
    paddingBottom: 40,
  },
  listItem: {
    ...theme.typography.paragraph,
    ...theme.globals.left,
    color: theme.palette.gray.main,
  },
  bulletPointIcon: {
    width: 15,
    height: 15,
    color: theme.palette.primary.main,
    marginRight: 10,
  },
  listItemText: {
    ...theme.typography.listItem,
    color: theme.palette.gray.main,
  },
  disclaimerText: {
    ...theme.typography.mediumText,
    color: theme.palette.gray.main,
    textAlign: 'left',
    paddingLeft: 25,
  },
  cookiesWarning: {
    paddingTop: 10,
  },
  linkButton: {
    width: '100%',
    textDecoration: 'none',
  },
});

const Register = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [email, setEmail] = useState(false);
  const [password, setPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);
  const [countryCode, setCountryCode] = useState('+52');
  const [phone, setPhone] = useState(false);
  const [cookies, setCookies] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const _attempMerchantSingUp = async (e) => {
    setIsLoading(true);
    if (email.length === 0) {
      setIsLoading(false);
      handleAlert('error', 'Ingresa tu correo electrónico');
      return;
    } else if (password.length === 0) {
      setIsLoading(false);
      handleAlert('error', 'Ingresa tu contraseña');
      return;
    } else if (confirmPassword.length === 0) {
      setIsLoading(false);
      handleAlert('error', 'Confirma tu contraseña');
      return;
    } else if (phone.length === 0) {
      setIsLoading(false);
      handleAlert('error', 'Ingresa tu número de teléfono');
      return;
    } else if (phone.length !== 10) {
      setIsLoading(false);
      handleAlert('error', 'Tu número de télefono debe de ser de 10 dígitos');
      return;
    } else if (password !== confirmPassword) {
      setIsLoading(false);
      handleAlert('error', 'Contraseñas diferentes');
      return;
    } else if (password.includes(phone)) {
      setIsLoading(false);
      handleAlert(
        'error',
        'La contraseña no debe incluir el número de teléfono'
      );
      return;
    } else if (password.toLowerCase().includes('nerito')) {
      setIsLoading(false);
      handleAlert('error', 'La contraseña no debe incluir "Nerito"');
      return;
    } else if (!CheckValue('password', password)) {
      setIsLoading(false);
      handleAlert(
        'error',
        'La contraseña debe tener mínimo 6 caracteres, incluyendo por lo menos un número y un caracter especial (!¡@#$%^&*(),.¿?":{}|<>_-)'
      );
      return;
    } else if (CheckValue('hasConsecutiveRepeat', password)) {
      setIsLoading(false);
      handleAlert(
        'error',
        'La contraseña no debe incluir el mismo caracter 3 veces consecutivas.'
      );
      return;
    } else if (CheckValue('hasConsecutive', password)) {
      setIsLoading(false);
      handleAlert(
        'error',
        'La contraseña no debe incluir 3 caracteres consecutivos.'
      );
      return;
    } else if (cookies === false) {
      setIsLoading(false);
      handleAlert('error', 'Debes aceptar las Cookies');
      return;
    }
    try {
      const signup = await Auth.signUp({
        username: email,
        password,
        attributes: {
          'custom:usertype': 'merchant',
          phone_number: countryCode + phone.trim(),
        },
        validationData: [],
      });

      fetch(process.env.REACT_APP_ZAPIER_REGISTER, {
        method: 'POST',
        body: JSON.stringify({
          pending: 'PENDIENTE',
          email: email,
          phoneNumber: countryCode + phone.trim(),
          leadSource: 'REGISTRO EN SISTEMA',
        }),
      })
        .then((response) => response.json())
        .then((data) => {})
        .catch((error) => {
          console.error('Error:', error);
        });

      if (signup.user.username === email) {
        props.history.push({
          pathname: '/verificar',
          state: { email: email, password },
        });
      }
    } catch (error) {
      setIsLoading(false);
      console.log('EDDYEDDYEDDY', error);
      console.log('SIGNUP-ERROR', error);
      handleAlert('error', CognitoErrors(error.message));
    }
  };

  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  };

  const classes = useStyles();
  return (
    <>
      {isLoading ? (
        <div className='styleCircularProgress'>
          <CircularProgress color='secondary' />
        </div>
      ) : (
        <div>
          <Grid container>
            <Grid xs={1}>
              <div className={classes.imageContainer}>
                <div className={classes.sideSVG} />
              </div>
            </Grid>
            <Grid xs={12} md={4}>
              <div className={classes.registerContainer}>
                <NeritoHeader />
                <div className={classes.centerVertical}>
                  <Typography className={classes.title}>
                    ¡Bienvenido!
                  </Typography>
                  <PrimaryInput
                    id='email'
                    name='email'
                    type='text'
                    placeholder='Email'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    fullWidth
                  />
                  <PrimaryInput
                    id='pwd'
                    name='password'
                    type={showPassword ? 'text' : 'password'}
                    placeholder='Contraseña'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    fullWidth
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          onClick={() => setShowPassword(!showPassword)}
                          onMouseDown={(e) => e.preventDefault()}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <PrimaryInput
                    id='cpwd'
                    name='confirmPassword'
                    type={showConfirmPassword ? 'text' : 'password'}
                    placeholder='Confirmar contraseña'
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    fullWidth
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                          onMouseDown={(e) => e.preventDefault()}
                          className='visibilityicon eyeIcon'
                        >
                          {showConfirmPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <div className={classes.phoneContainer}>
                    <PrimarySelect
                      name='countryCode'
                      value={countryCode}
                      onChange={(e) => setCountryCode(e.target.value)}
                      marginLeft='0'
                      content={countryCodes().map((code) => {
                        return <option value={code.value}>{code.label}</option>;
                      })}
                    ></PrimarySelect>
                    <PrimaryInput
                      id='phone'
                      name='phone'
                      type='number'
                      placeholder='(662)555-5555'
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      marginRight='0'
                      fullWidth
                    />
                  </div>
                  <Grid container className={classes.checkboxContainer}>
                    <Grid item xs={12}>
                      <Typography
                        className={`${classes.checkboxText} ${classes.cookiesWarning}`}
                      >
                        Utilizamos cookies para mejorar tu experiencia en
                        nuestra web.{' '}
                      </Typography>
                      <Typography className={`${classes.checkboxText}`}>
                        Infórmate más sobre el uso de cookies{' '}
                        <span
                          className={classes.link}
                          onClick={() => setShowModal(true)}
                        >
                          aquí.
                        </span>
                      </Typography>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id='cookies'
                            name='cookies'
                            checked={cookies}
                            onChange={() => {
                              setCookies(!cookies);
                            }}
                            color='#6EB89D'
                          />
                        }
                        label={
                          <Typography className={classes.checkboxText}>
                            Aceptar Cookies
                          </Typography>
                        }
                      />
                      <DefaultModal
                        open={showModal}
                        handleClose={() => setShowModal(false)}
                        modalType='cookies'
                      />
                    </Grid>
                  </Grid>
                </div>
                <div className={classes.centerVertical}>
                  <PrimaryButton
                    type='submit'
                    onClick={_attempMerchantSingUp}
                    marginVertical={10}
                    fullWidth
                  >
                    Registrarme
                  </PrimaryButton>
                  <Link
                    className={classes.linkButton}
                    to={{
                      pathname: 'https://nerito.mx/login',
                    }}
                    target='_blank'
                  >
                    <SecondaryButton
                      fullWidth
                      marginVertical={10}
                      marginHorizontal='0'
                    >
                      Iniciar Sesión
                    </SecondaryButton>
                  </Link>
                </div>
              </div>
            </Grid>
            <Grid xs={12} sm={7}>
              <div className={classes.imageContainer}>
                <div className={classes.background}>
                  <div className={classes.wave}></div>
                </div>
              </div>
            </Grid>
          </Grid>
          <div className={classes.promotionalContainer}>
            <div className={classes.promotionalHeader}>
              <Typography className={classes.paragraph}>
                Di Nerito en la palma de tu mano, con una plataforma ágil que te
                permitirá ampliar tus canales de ventas, llegar a más clientes y
                brindarles tus servicios en un solo lugar.
              </Typography>
              <Typography className={classes.slogan}>
                ¡Únete a la red digital Nerito y reactiva tu economía!
              </Typography>
            </div>
            {/* <div className={classes.videoContainer}>
              <Typography className={classes.videoSubtitle}>
                Regístrate en Nerito
              </Typography>
              <Typography className={classes.videoTitle}>
                ¡En 3 simples pasos!
              </Typography>
              <YouTube
                videoId='-EoNrg_DR3s'
                opts={{
                  height: '390',
                  width: '640',
                  playerVars: {
                    // https://developers.google.com/youtube/player_parameters
                    // autoplay: 1,
                  },
                }}
              />
            </div> */}
            <div className={classes.documentationContainer}>
              <p className={classes.documentationTitle}>
                Para ser parte de Nerito sólo necesitas:
              </p>
              <div className={classes.listContainer}>
                <ListItem className={classes.listItem}>
                  <Circle className={classes.bulletPointIcon}></Circle>

                  <Typography className={classes.listItemText}>
                    Comprobante de Domicilio
                  </Typography>
                </ListItem>
                <ListItem className={classes.listItem}>
                  <Circle className={classes.bulletPointIcon}></Circle>

                  <Typography className={classes.listItemText}>
                    Acta constitutiva ¹
                  </Typography>
                </ListItem>
                <ListItem className={classes.listItem}>
                  <Circle className={classes.bulletPointIcon}></Circle>

                  <Typography className={classes.listItemText}>
                    Identificación del representante
                  </Typography>
                </ListItem>
                <ListItem className={classes.listItem}>
                  <Typography className={classes.disclaimerText}>
                    Sólo para persona moral¹
                  </Typography>
                </ListItem>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default withSnackbar(Register);
