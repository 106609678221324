import React, { useState } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import './NavBar.css';
import theme from '../../styles/theme.js';
import { makeStyles } from '@material-ui/core/styles';
import NeritoIcon from '../../assets/images/logos/neritoIcon.svg'

const useStyles = makeStyles({
  solidGreen: {
    width: '100%',
    position: 'fixed',
    top: 0,
    boxShadow: theme.shadows[5],
    backgroundColor: theme.palette.white.main,
    zIndex: 10,
  },
  navBarContainer: {
    backgroundColor: theme.palette.white.main,
    height: '80px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '30px 10%',
    [theme.breakpoints.down(theme.breakpoints.values.mn)]: {
      justifyContent: 'center'
    },
  },
  logoContainer: {
    position: 'relative',
    [theme.breakpoints.down(theme.breakpoints.values.mn)]: {
      position: 'relative',
      marginLeft: '0'
    },
  },
  menuIcon: {
    display: 'none',
    color: theme.palette.black.main,
    [theme.breakpoints.down(theme.breakpoints.values.mn)]: {
      display: 'block',
      position: 'absolute',
      top: '0',
      right: '0',
      transform: 'translate(-100%, 60%)',
      fontSize: '1.8rem',
      cursor: 'pointer',
    }
  },
  navLi: {
    display: 'inline-block',
    padding: '0px 0 0 8px',
    transition: 'all 0.3s ease 0s',
  },
  navMenu: {
    listStyle: 'none',
    [theme.breakpoints.down(theme.breakpoints.values.mn)]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      height: '550px',
      position: 'absolute',
      top: '80px',
      left: '-100%',
      opacity: '1',
      transition: 'all 0.5s ease'
    }
  },
  active: {
    [theme.breakpoints.down(theme.breakpoints.values.mn)]: {
      boxShadow: 'inset 0 0 0 1000px rgba(0, 164, 140, 0.98)',
      left: '0',
      opacity: '1',
      transition: 'all 0.5s ease',
      zIndex: '1',
      height: '300px',
    }
  },
  linkItem: {
    fontFamily: 'Montserrat',
    color: theme.palette.black.main,
    textDecoration: 'none',
    fontSize: '19px',
    padding: '0.5rem 1rem',
    fontWeight: '600',
    '&:hover': {
      borderBottom: `2px solid ${theme.palette.green.main}`,
    },
    [theme.breakpoints.down(theme.breakpoints.values.mn)]: {
      textAlign: 'center',
      // padding: '2rem',
      width: '100%',
      display: 'table',
      '&:hover': {
        background: 'linear-gradient(90deg, #44a38b 0%, #19c69a 50%, #44a38b 100%)',
        borderRadius: '0'
      }
    }
  },
  linkButton: {
    backgroundColor: '#009b84',
    color: theme.palette.black.main,
    textDecoration: 'none',
    fontSize: '24px',
    fontWeight: '700',
    padding: '0.5rem 2rem',
    borderRadius: '30px',
    '&:hover': {
      backgroundColor: '#00b89c',
      color: theme.palette.black.main,
      transition: 'all 0.2s ease-out',
    },
    [theme.breakpoints.down(theme.breakpoints.values.mn)]: {
      textAlign: 'center',
      width: '100%',
      alignSelf: 'center',
      display: 'table',
      fontSize: '1rem',
      marginBottom: '40px',
    }
  },
  oneRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.down(theme.breakpoints.values.mn)]: {
      display: 'block',
      flexDirection: 'row',
      justifyContent: 'space-between'
    }
  },
  buttonContainer2: {
    minWidth: '235px',
    marginTop: '20px'
  },
  menuContainer: {
    display: 'flex',
    flexDirection: 'row',
  }
})

function NavBar() {

  const classes = useStyles();

  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);

  const closeMobileMenu = () => setClick(false);

  return (
    <div className={classes.solidGreen}>
      <div className={classes.navBarContainer}>
        <div className={classes.logoContainer}>
          <a href='/home'>
            <img
              src={NeritoIcon}
              alt=''
            />
          </a>
        </div>
        <div className={classes.menuContainer}>
          <div className={classes.menuIcon} onClick={handleClick}>
            {click ? <FaTimes /> : <FaBars />}
          </div>
          <ul className={click ? `${classes.navMenu} ${classes.active}` : classes.navMenu}>
            <a
              href="/nuestraApp"
              to='/nuestraApp'
              onClick={closeMobileMenu}
              className={classes.linkItem}
            >
              Nuestra App
            </a>
            <a
              href="https://nerito.mx/login"
              to='/comercios'
              target='_blank'
              rel='noopener noreferrer'
              onClick={closeMobileMenu}
              className={classes.linkItem}
            >
              Nerito Empresas
            </a>
            <a
              href="/home/#OurGroup"
              to='/home/#OurGroup'
              onClick={closeMobileMenu}
              className={classes.linkItem}
            >
              Quienes somos
            </a>
            <a
              href="/contacto"
              to='/contacto'
              onClick={closeMobileMenu}
              className={classes.linkItem}
            >
              Contacto
            </a>
            {/* {MenuItems.map((item, index) => {
              switch (item.cName) {
                case 'linkItem':
                  return (
                    <li key={index} className={classes.navLi}>
                      <Link
                        to='/' target='OurApp'
                        onClick={closeMobileMenu}
                        className={classes.linkItem}
                      >
                        {item.title}
                      </Link>
                    </li>
                  );

                case 'linkButton':
                  return (
                    <li key={index} className={classes.navLi}>
                      <a
                        href={item.href}
                        target='_blank'
                        rel='noopener noreferrer'
                        className={classes.linkButton}
                      >
                        {item.title}
                      </a>
                    </li>
                  );

                default:
                  return (
                    <li key={index} className={classes.navLi}>
                      <Link
                        to={item.url}
                        onClick={closeMobileMenu}
                        className={item.cName}
                      >
                        {item.title}
                      </Link>
                    </li>
                  );

              }
            })} */}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default NavBar;